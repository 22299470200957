<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~animate.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';
@import '~@vueform/multiselect/themes/default.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
body.no-background {
  background: none transparent !important;
}

.btn-icon {
  border: 1px solid #d3d3d3 !important;
}

thead tr {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

thead th {
  background-color: #F3F6F9 !important;
  border-bottom: 0;
  letter-spacing: 1px;
  font-weight: 600;
  color: #B5B5C3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
}

thead th:first-child {
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}

thead th:last-child {
  border-top-right-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
}

:deep .table td {
  vertical-align: middle !important;
}

.custom-select:disabled {
  background-color: #e4e6ef  !important;
}

.table-striped tbody tr:nth-of-type(even){
  background-color: #eff4f9;
}
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import { SET_GLOBAL_SETTINGS } from '@/core/services/store/common.module';

import axios from 'axios';

export default {
  name: 'MainMemlistVue',
  components: {
    
  },
  
  mounted() {
    // Override layout config
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.fetch_global_settings();
  },
  methods: {
    async fetch_global_settings() {
      try {
        const res = await axios.get(`/system/public`);
        if (res.status === 200) {
          this.$store.dispatch(SET_GLOBAL_SETTINGS, res.data);
        }
      } catch (error) {
        console.error("Error fetching global settings:", error);
      }
    },
    
  },
};
</script>
